<template>
  <div style="background-color: #171726;min-height: 100vh;overflow: scroll;">
    <div class="game_top">
      <nav-bar :icon="'img/backw.png'" :textColor="'#ffffff'" :backColor="'#171726'" />
      <van-loading size="24px" v-if="loading" style="text-align:center;" class="margin-top">加载中...</van-loading>
      <div v-else>
        <div class="certList_tit">
          <!-- <img src="img/game/tit_hint.png" alt=""> -->
          <span>热门游戏</span>
        </div>
        <div class="display_flex align-items_center flex_wrap">
          <div class="certList_box display_flex flex-direction_column justify-content_flex-center align-items_center"
            v-for="(item, index) in hotGame" :key="index" @click="goCertGame(item.game_auth.game_id, item.game_auth.state)">
            <img :src="item.game_logo" alt="">
            <span>{{ item.game_name }}</span>
            <div class="cert_hint" v-if="item.game_auth.state == 1" style="background-image: url(img/game/cert_sh.png);">
            </div>
            <div class="cert_hint" v-if="item.game_auth.state == 2" style="background-image: url(img/game/cert_renz.png);">
            </div>
          </div>
        </div>
        <div class="certList_tit">
          <span>其他游戏</span>
        </div>
        <!-- <div class="cert_more">敬请期待</div> -->
        <div class="display_flex align-items_center flex_wrap">
          <div class="certList_box display_flex flex-direction_column justify-content_flex-center align-items_center"
            v-for="(item, index) in otherGanme" :key="index" @click="goCertGame(item.game_auth.game_id, item.game_auth.state)">
            <img :src="item.game_logo" alt="">
            <span>{{ item.game_name }}</span>
            <div class="cert_hint" v-if="item.game_auth.state == 1" style="background-image: url(img/game/cert_sh.png);">
            </div>
            <div class="cert_hint" v-if="item.game_auth.state == 2" style="background-image: url(img/game/cert_renz.png);">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/game.css"
import NavBar from '@/components/navBar.vue';
import { gameList } from "@/api/api";
export default {
  name: 'gameList',
  components: {
    NavBar
  },
  data() {
    return {
      loading: true,
      hotGame: [],
      otherGanme: [],
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      let a1 = new Promise((resolve, reject) => {
        gameList({ game_type: 1 }).then(r => {
          resolve(r)
        },
          error => {
            reject(r)
          }).catch((error) => {
            reject(r)
          })
      })
      let a2 = new Promise((resolve, reject) => {
        gameList({ game_type: 2 }).then(data => {
          resolve(data)
        },
          error => {
            reject(data)
          }).catch((error) => {
            reject(data)
          })
      })
      Promise.all([a1, a2]).then(arry => {
        this.hotGame = arry[0].data
        this.otherGanme = arry[1].data
        this.loading = false
      }).catch(reason => {
        console.log(reason)
      });

    },
    goCertGame(id, status) {
      console.log(id,status)
      if (status == 0 || status == 3) {
        let str = {
          game_id: id
        }
        let params = JSON.stringify(str)
        this.$bridge.callhandler('certGame', params, (data) => {
          // 处理返回数据
        })
      } else {
        return
      }

    },
    closePage() {
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
      })
    },

  },
};
</script>
